@import "../../scss/variables";
@import "../../scss/functions";
@import "../../scss/mixins/hacks";
@import "../../scss/mixins/direction";
@import "../../scss/mixins/post-card";

.line__active {
  position: absolute;
  bottom: 0;
  height: 6px;
  width: 100%;
}

.under_line__active {
  background: $main-theme-color-dark;
}

.text-success {
  color: #2ba847;
}

.text-danger {
  color: red;
}

.product__prices {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: $font-weight-bold;
  letter-spacing: -0.03em;
  color: $price-color;
}

.color-lightgray {
  color: #757373;
  font-weight: lighter;
}

.product-card__new-price {
  color: $price-new-color;
}

.product-card__new-price_old_value {
  color: #000;
}

.product-card__old-price {
  margin-left: 10px;
  color: $price-old-color;
  font-weight: $font-weight-normal;
  font-size: 13px;
  text-decoration: line-through;
}

.test {
  .slick-prevent-click {
    color: red !important;

  }
}

.product-gallery__carousel {
  margin-top: 16px;
  padding: 0 5px 5px;

  .slick-list {
    margin: 0 -5px;
  }


  .slick-track {
    margin-top: 0 !important;
  }

}

.product__gallery {
  -ms-grid-row: 1;
  -ms-grid-row-span: 6;
  -ms-grid-column: 1;

  @include only-ie() {
    @include direction {
      #{$margin-inline-end}: 40px;
    }
  }

  grid-row-start: 1;
  grid-row-end: 6;
  min-height: 0;
  border: 1px solid #ebebeb;
  padding: 5px;
  height: 100%;

  .product-gallery__featured {
    box-shadow: unset;
  }
}


@media (max-width: breakpoint(sm-end)) {

  .product__gallery {
    margin-bottom: 24px;
    padding-left: 30px;
    padding-right: 25px;
  }
}


.product-gallery__featured .product-image__body {
  box-shadow: none;
}

@media (max-width: 870px) {
  .product-gallery__featured {
    .slick-prevent-click {
      height: 300px;
    }
  }
}


@media (max-width: 767px) {
  .product-gallery__featured {
    .slick-prevent-click {
      height: 360px;
    }
  }
  .product-gallery__carousel {
    .slick-track {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 575px) {
  .product__gallery {
    margin-left: 10px;
  }
}


/////////////////////////////////// product inner page gallery

// from product-gallery.scss

.product-gallery__featured {
  position: relative;
  box-shadow: $product-gallery-item-default-shadow;
  padding: 2px;
  border-radius: $product-gallery-item-border-radius;
  aspect-ratio: 1;

  .product-image {
    padding: 20px;
  }

  .product-image__body:focus {
    outline: none;
  }
}

.product-gallery__zoom {
  position: absolute;
  cursor: pointer;
  padding: 11px;
  border: none;
  z-index: 2;
  top: 0;
  right: 0;
  background: $product-gallery-zoom-button-normal-bg-color;
  fill: $product-gallery-zoom-button-normal-icon-color;
  transition: background 0.2s, fill 0.2s;
  border-top-right-radius: 2.5px;
  border-bottom-left-radius: 2px;
  transform: scaleX(1);
  @include direction {
    #{$inset-inline-end}: 0;
    #{$border-start-end-radius}: $product-gallery-item-border-radius + 0.5px;
    #{$border-end-start-radius}: 2px;
    transform: scaleX($transform-direction);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: $product-gallery-zoom-button-hover-bg-color;
    fill: $product-gallery-zoom-button-hover-icon-color;
  }

  &:active {
    background: $product-gallery-zoom-button-active-bg-color;
    fill: $product-gallery-zoom-button-active-icon-color;
  }
}


.product-gallery__carousel-item {
  vertical-align: middle;
  width: 100%;
  cursor: pointer;
  display: block;
  box-shadow: $product-gallery-item-default-shadow;
  padding: 12px;
  border-radius: $product-gallery-item-border-radius;
  border: none;
  background: transparent;

  &:focus {
    outline: none;
  }
}


@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
  .product__gallery {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 576px) {
  .product__gallery {
    margin: 0 !important;
  }
}

@media (max-width: 500px) {

  .product__gallery {
    //margin: 0 20px !important;
    padding: 0 !important;
    height: initial !important;
  }
}

// from custom.scss

.product-image--location--gallery {
  min-height: 260px;
  height: 100%;

  .product-image__img {
    max-height: unset;
  }

  .product-image__body {
    display: unset;
    aspect-ratio: unset;
  }

  .owl-carousel .owl-item & .product-image__img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

//--//

.product-image__body {
  display: block;
  position: relative;
  -webkit-box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
  box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
  width: 100%;
  aspect-ratio: 1/1;
  max-height: 359px;

  img {
    width: 100% !important;
    object-fit: contain;
    height: 100%;
    display: block;
  }
}

.product-image__img {
  display: block;
  width: 100%;
  object-fit: contain;
  position: relative;
  padding: 3px 7px;
  max-height: 359px;

  &,
  .owl-carousel .owl-item & {
    @include only-ie {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

//--//

.product-gallery__carousel-item--active .product-image__body {
  box-shadow: none;
}

.small-img-car_fms {
  min-height: 90px;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    max-height: 65px;
    position: absolute;
    padding: 3px 7px;
    object-fit: contain;
  }
}

.product-small-img {
  height: initial !important;
  padding: 5px 0 !important;
}


.currency_value {
  display: flex;
  gap: 2px;
  justify-content: center;
}

/////////////// block header


.block-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-header__title {
  margin-bottom: 0;
  font-size: 20px;
}

.block-header__divider {
  flex-grow: 1;
  height: $block-header-divider-size;
  background: $block-header-divider-color;
}

.block-header__groups-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: $font-weight-medium;
  white-space: nowrap;

  li + li {
    @include direction {
      #{$margin-inline-start}: 3px;
    }
  }
}

.block-header__title__custom {
  display: flex;
  justify-content: center;
  align-items: center;
  //margin: 20px 0 !important;
  margin-bottom: 15px !important;
  width: 100%;
}

.title-line-fms {
  width: 70px;
  height: 3px;
  background: black;

}

.title-name-fms {
  padding: 0 10px;
  white-space: nowrap;
  min-height: 20px;
  font-size: 20px;
}

.block-header__group {
  background: transparent;
  border: none;
  font-family: $body-font-family;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  display: block;
  color: inherit;
  padding: 2px 11px 1px;
  border-radius: 12px;
  box-shadow: $block-header-filter-default-shadow;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    box-shadow: $block-header-filter-hover-shadow;
    color: inherit;
  }
}

.block-header__group--active {
  &,
  &:focus,
  &:hover {
    cursor: default;
    box-shadow: $block-header-filter-active-shadow;
  }
}

.block-header__arrows-list {
  display: flex;
}

.block-header__arrow {
  width: 27px;
  height: 29px;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: $block-header-arrow-default-color;
  border-radius: 1.5px;
  background: $block-header-arrow-default-bg;
  transition: all .15s;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background: $block-header-arrow-hover-bg;
    fill: $block-header-arrow-hover-color;
  }

  &:active {
    transition-duration: 0s;
    background: $block-header-arrow-active-bg;
    fill: $block-header-arrow-active-color;
  }

  & + & {
    @include direction {
      #{$margin-inline-start}: 3px;
    }
  }

  svg {
    @include direction {
      transform: scaleX(1 * $transform-direction);
    }
  }
}

.block-header__arrow--left svg {
  position: relative;

  @include direction {
    #{$inset-inline-start}: -1px;
  }
}

.block-header__arrow--right svg {
  position: relative;

  @include direction {
    #{$inset-inline-start}: 1px;
  }
}


.block-header__title + .block-header__divider {
  @include direction {
    #{$margin-inline-start}: 16px;
  }
}

.block-header__divider + .block-header__groups-list {
  @include direction {
    #{$margin-inline-start}: 12px;
  }
}

.block-header__groups-list + .block-header__arrows-list {
  @include direction {
    #{$margin-inline-start}: 10px;
  }
}

.block-header__divider + .block-header__arrows-list {
  @include direction {
    #{$margin-inline-start}: 16px;
  }
}


@media (max-width: breakpoint(sm-end)) {
  .block-header {
    display: flex;
    flex-wrap: wrap;
  }
  .block-header__groups-list {
    padding: 8px 0;
    margin-top: 8px;
    margin-bottom: -8px;
    order: 100;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .block-header__divider + .block-header__groups-list {
    @include direction {
      #{$margin-inline-start}: auto;
      #{$margin-inline-end}: auto;
    }
  }
  .block-header__divider + .block-header__groups-list + .block-header__arrows-list {
    @include direction {
      #{$margin-inline-start}: 16px;
    }
  }
}

@media screen and (max-width: 500px) {

  .title-name-fms {
    min-height: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 414px) {
  .title-name-fms {
    font-size: 19px !important;
    white-space: unset !important;
    text-align: center;
  }

}

@media (min-width: 501px) and (max-width: 800px) {

  .title-name-fms {
    min-height: 20px;
  }
}


.breadcrumb {
  display: flex;
  background: transparent;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;

  ol, ul {
    display: flex;
  }

  li {
    list-style-type: none !important;
    display: flex;
    margin-left: 10px;
  }

  & > li:first-child {
    margin: 0 !important;
  }
}

.minus_hide {
  display: none !important;
}

@media (max-width: 576px) {
  .input-number__input {
    border: 1px solid #cccccc;
  }
}

.input-number__sub {
  @include direction {
    #{$inset-inline-start}: 1px;
  }
}

.input-number__add {
  @include direction {
    #{$inset-inline-end}: 1px;
  }

  &:after {
    width: 2px;
    height: 8px;
  }
}

.form-control-sm {
  height: calc(1.875rem + 2px);
  line-height: 1.25;
  padding: 0.3125rem 0.5rem;
}

.product__quantity {
  width: 120px;
}

.product__quantity {
  .cart-quantity-responsive {
    height: 34px;
  }
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {

  .product__quantity {
    width: 100px;
  }
}


// PageHeader.tsx

$local-padding: 12px;

.page-header {
  background-color: #f5f5f5;
  margin-bottom: 30px;
}


.breadcrumb-item {
  position: relative;

  @include direction {
    #{$padding-inline-start}: 0;
    #{$padding-inline-end}: 6px + $local-padding * 2;
  }

  a {
    color: $breadcrumb-link-default-color;
    transition: color .15s;
  }

  a:hover {
    color: $main-theme-color-light;
  }

  & + & {
    @include direction {
      #{$padding-inline-start}: 0;
      #{$padding-inline-end}: 6px + $local-padding * 2;
    }

    &::before {
      display: none;
    }
  }
}

.breadcrumb-item-active {
  color: $breadcrumb-link-active-color;
}

.page-header__breadcrumb {
  padding: 10px 0;
}

.page-header__title {
  padding-top: 6px;

  h1 {
    margin-bottom: 0;
  }
}

.breadcrumb-arrow {
  fill: $breadcrumb-arrow-color;
  position: relative;
  top: 5px;
  margin-left: 10px;
  @include direction {
    #{$inset-inline-end}: $local-padding - 1px;
    transform: scaleX(1 * $transform-direction);
  }
}


@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
  .page-header__title {
    padding-top: 4px;

    h1 {
      margin-bottom: 0;
    }
  }
}


@media screen and (max-width: 768px) {

  .page-header__container {
    display: none !important;
  }
  .page-header__title {
    padding-top: 2px;

    h1 {
      margin-bottom: 0;
      font-size: 30px;
    }
  }

}

@media (max-width: breakpoint(xs-end)) {

  .page-header {
    margin-top: 0;
    margin-bottom: 47px;
  }
}

/// pagination.tsx


.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.page-link {
  font-weight: $font-weight-medium;
  border-radius: 2px;
  border: none;
  line-height: 1;
  margin: 0 2px;
  padding: 9px 10px 8px;
  height: 33px;
  background: $pagination-bg;
  color: $pagination-font-color;
  cursor: pointer;
  user-select: none;
}

.page-link:hover,
.page-link:focus {
  box-shadow: none;
  background: $pagination-hover-bg;
  color: $pagination-hover-font-color;
}

.page-link--with-arrow {
  width: 29px;
  position: relative;

  .page-link__arrow {
    position: absolute;
    fill: currentColor;
    top: 10px;

    @include direction {
      transform: scaleX(1 * $transform-direction);
    }
  }

  .page-link__arrow--left {
    @include direction {
      #{$inset-inline-start}: 10px;
    }
  }

  .page-link__arrow--right {
    @include direction {
      #{$inset-inline-start}: 11px;
    }
  }
}

.page-item:first-child .page-link {
  border-radius: 2px;

  @include direction {
    #{$margin-inline-start}: 0;
  }
}

.page-item:last-child .page-link {
  border-radius: 2px;

  @include direction {
    #{$margin-inline-end}: 0;
  }
}

.page-item-disabled {
  .page-link {
    background: $pagination-disabled-bg;
    color: $pagination-disabled-font-color;
  }
}

.page-item-active {
  .page-link {
    background: $main-theme-color-dark;
    cursor: default;
    z-index: 2;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
}

/// CustomBottomNavigation.tsx

.bottomNavigation-wrapper {
  width: 100%;
  height: 64px;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
  border-top: 1px solid #ebebeb;
  -webkit-mask: radial-gradient(37px at 50% -1px, #0000 98%, #000);
  overflow: hidden;

  @media screen and (max-width: 425px) {
    -webkit-mask: radial-gradient(37px at 50% -1px, #0000 98%, #000);
  }

  .indicatpr_area {
    height: unset;
  }
}

.bottom-nav-item--title {
  margin-top: 5px;
  font-size: 10.3px;
  margin-bottom: 0;
}

.bottom-nav-item--title-active {
  color: $footer-icon-fill-stroke-color;
}

.local-item {
  path {
    stroke:$main-theme-color-light ;
    //fill: #AAAAAA !important;
  }

  circle {
    stroke: $main-theme-color-light;
    //fill: $main-theme-color-light !important;
  }
}

.local-item-active {
  background: transparent !important;

  path {
    fill: $main-theme-color-light;
    stroke: white ;
  }

  rect {
    fill: $main-theme-color-dark;
  }

  circle {
    fill: $main-theme-color-light;
  }
}

.local-item, .local-item.active {
  span {
    height: unset
  }
}

.bottom-nav-item {
  width: 20% !important;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all 0.3s ease;
}

.bottom-nav {
  .indicator__value {
    margin-left: -8px;
  }

  .indicator__value {
    background: white;
    color: #757575;
  }

  p {
    left: 0;
    top: 26px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #757575;
  }

  .indicator {
    a {
      height: unset;
    }
  }

  transition: 1s;
  background: #ffffff !important;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1) !important;

  .bottom-nav-item.active {
    background-color: #f5f5f5 !important;

    p {
      color: $footer-icon-fill-stroke-color !important;
    }

    .indicator__value {
      color: $footer-icon-fill-stroke-color;
    }

    path {
      fill: $footer-icon-fill-stroke-color;
    }
  }

  .bottom-nav-item.false {
    path {
      fill: #757575;
    }
  }

  .indicator__area {
    position: relative;
    background: 0 0;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    border-radius: 2px;
    display: flex;
    align-items: center;
    //fill: #fff;
    justify-content: center;
    height: unset !important;
  }
}

.mob_area {
  padding: 0 !important;
}

// postCard.tsx

.block-posts {
  position: relative;
  z-index: 1;

  .slick-list {
    margin: 0 -15px;
  }

  .slick-slide {
    padding: 0 15px;
  }

  .slick-slide > div > div {
    vertical-align: middle;

    &:focus {
      outline: none;
    }
  }
}

.post-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.block-posts--layout--list-sm {
  @media (min-width: breakpoint(sm-start)) {
    .post-card {
      @include post-card-list();
      @include post-card-list-sm();
    }
  }

  @media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .post-card {
      @include post-card-list-xs();
    }
  }

  @media (max-width: breakpoint(xs-end)) {
    .post-card {
      @include post-card-grid();
    }
  }
}

.block-posts--layout--grid-nl {
  .post-card {
    @include post-card-grid();
  }
}

.post-card__info {
  margin-top: 10px;
  width: 100%;

  .post-card__read-data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .post-card__date,
    .post-card__read-more {
      margin: 0;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }
}

.post-card__image {
  position: relative;
  overflow: hidden;
  border-radius: 1.5px;
  width: 100%;
  height: 100%;
  max-height: 300px;

  span {
    position: relative !important;
  }

  img {
    position: unset !important;
    max-width: 100%;
    width: 100% !important;
    height: auto !important;
    object-fit: contain;
  }

  ::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $post-image-overlay-bg;
    opacity: 0;
    transition: opacity 0.6s;
    z-index: 1;
  }

  &:hover {
    ::before {
      opacity: 1;
    }
  }
}

.post-card__category {
  font-size: 14px;
  color: $post-card-meta-font-color;

  a {
    color: inherit;
    transition: 0.15s;
  }

  a:hover {
    color: $link-hover-color;
  }
}

.post-card__date {
  font-size: 14px;
  color: $post-card-meta-font-color;
}

.post-card__name {
  font-size: 20px;
  line-height: 1.125;
  font-weight: $font-weight-medium;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 64px;

  @media (max-width: 767px) {
    height: 54px;
  }

  @media (max-width: 479px) {
    height: 46px;
  }

  a {
    color: inherit;
    transition: 0.15s;
  }

  a:hover {
    color: $link-hover-color;
  }
}

.post-card__content {
  font-size: 15px;
  line-height: 24px;
}

.post-card--layout--related {
  .post-card__name {
    width: 100%;
    font-size: 17px;
    margin-top: 16px;
    line-height: 24px;

    a {
      transition: all 0.2s;
    }

    a:hover {
      color: $link-hover-color;
    }
  }

  .post-card__date {
    margin-top: 8px;
    font-size: 15px;
  }

  @media (max-width: breakpoint(sm-end)) {
    .post-card__name {
      margin-top: 12px;
      font-size: 15px;
      line-height: 20px;
    }
    .post-card__date {
      margin-top: 4px;
      font-size: 14px;
    }
  }
}

.post-card--layout--grid {
  @include post-card-grid();

  &.post-card--size--lg {
    @include post-card-grid-lg();
  }
}

.post-card--layout--list {
  @include post-card-list();

  &.post-card--size--nl {
    @include post-card-list-nl();
  }

  &.post-card--size--sm {
    @include post-card-list-sm();
  }
}

// SocialLinks.scss


$local-gutter: 7px;


.social-links__list {
  list-style: none;
  padding: 0;
  margin: 0 0 (-$local-gutter) (-$local-gutter);
  display: flex;
  flex-wrap: wrap;
}

.social-links__item {
  flex-shrink: 0;
  margin-left: $local-gutter;
  margin-right: 5px;
  margin-bottom: $local-gutter;
}

.social-links__link {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  transition: all 0.2s;

  &,
  &:hover {
    color: $social-links-font-color;
  }

  &:hover {
    opacity: 0.8;
  }

  span {
    position: relative !important;
    width: 30px !important;
    height: 30px !important;
  }

  img {
    width: 100% !important;
  }
}

.social-links--shape--circle {
  .social-links__link {
    height: 29px;
    width: 29px;
  }
}

.social-links--shape--rounded {
  .social-links__link {
    border-radius: 2px;
  }
}

@each $type, $link in $social-links {
  .social-links__link--type--#{$type} {
    @if (map_get($link, bg-color) != $social-links-bg-color) {
      background: map_get($link, bg-color);
    }
    @if (map_get($link, font-color) != $social-links-font-color) {
      &,
      &:hover {
        color: map_get($link, font-color);
      }
    }
  }
}

.social-links__link img {
  width: 100%
}

.social-links__link--type--youtube {
  color: $footer-social-link-color !important;
  background-color: white;
}

///////////// WishIcon.tsx

.wishlist-circle {
  path {
    fill: #ccc;

    &:focus {
      outline: none;
    }
  }
}

.wishlist-circle:focus {
  outline: none !important
}

.wishlist-icon-wrapper {
  cursor: pointer;
  margin-left: 23px;
  padding-bottom: 10px;

  path{
    stroke: #AAA;
  }

  &:hover{
    path{
      stroke: $main-theme-color-light;
    }
  }
}

.wishlist-circle-clicked {
  path {
    fill: $main-theme-color-dark;
  }
}

.inner-wishlist {
  path {
    fill: $main-theme-color-light;

    > &:focus {
      outline: none;
    }
  }

  > &:focus {
    outline: none;
  }
}

// prices.tsx

.product-card__prices {
  //margin-top: 14px;
  //line-height: 1;
  font-weight: $font-weight-medium;
  color: black;
  display: flex;
  gap: 5px;
}

///////////// upsell.jsx
.uPsell-title {
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.upsell_body {
  .product__content {

    :global(.customInnerPage_product-gallery__featured__eQMQK){
      height: 300px;
    }

    :global(.customInnerPage_product-gallery__carousel__21ptU){
      display: none;
    }
    //.product__gallery {
    //  .product-image__img {
    //    max-height: 300px;
    //  }
    //}

    .product__info {
      text-align: left;
      padding-top: 16.5px;

      h1 {
        text-align: left;
        margin: 5px 0;
      }

      .product__prices {
        margin: 0 0 15px;

        .product-card__new-price {
          .currency_value {
            font-size: 20px;
          }
        }

      }

      .short_description_block {
        .product-inner-description-title {
          font-size: 20px;
          margin-bottom: 5px;
          text-align: start;
        }
      }

      .product__meta {
        margin: 15px 0 10px;
        border: none;
        padding: 0;
      }
    }

    .product__sidebar {
      .product__options {
        .product__actions {
          gap: 15px !important;

          .no-thanks,
          button {
            font-family: $body-font-family;

            span {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.upsell_body {

  .product__content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 50% 50%;
    grid-template-columns: [gallery] calc(50% - 20px) [info] calc(50% - 20px);
    grid-template-rows: auto auto auto auto auto;
    grid-column-gap: 40px;
  }

  .product-image.product-image--location--gallery {
    padding: 0 !important;
  }

  .product-image__img {
    padding: 0 !important;
  }

  .btn.btn-orange.rounded-pills {
    height: 100% !important;
  }

  .product__actions {
    margin: 0 !important;
    display: flex;
    gap: 20px !important;
    align-items: center;
  }

  .product__name {
    font-size: 22px !important;
    text-align: left !important;
  }

  .product-inner-description-title {
    font-weight: 600 !important;
  }
}

.ups-product__info {
  text-align: left !important;
}


.item_overlay {
  height: 100%;
  width: 100%;
  background-color: transparent;
  opacity: 0.4;
  display: block;
  z-index: 1;
  position: absolute;
  margin: 0;
  transition: background-color 0.2s;
}

.product-card__image.cross__prods {
  flex-shrink: 0;

  &:hover .item_overlay {
    background-color: gray;
  }
}

.input-number {
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  border: 0.5px solid #AAA;
  background: #FFF;
  width: 100%;
  overflow: hidden;

  .input-number__input {
    -moz-appearance: textfield;
    display: block;
    //width: 100%;
    //min-width: 88px;
    text-align: center;
    border: none;
    //border-bottom: 1px solid #e3e3e3;
    font-size: 16px;
    //padding: 16px;
    //height: 32px;
    background: none;

    &:focus{
      outline: none;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  .input-number__add,
  .input-number__sub {
    //position: absolute;
    //height: calc(100% - 2px);
    //width: 35px;
    //top: 1px;
    cursor: pointer;
    //user-select: none;
    //opacity: .3;
    transition: opacity .18s;
    //
    //
    //
    //&:before,
    //&:after {
    //  display: block;
    //  content: '';
    //  position: absolute;
    //  top: 50%;
    //  left: 50%;
    //  transform: translateX(-50%) translateY(-50%);
    //  background: currentColor;
    //}
    //
    //&:before {
    //  width: 8px;
    //  height: 2px;
    //}

    &:hover {
      //opacity: 1;

      rect:first-child{
        //fill: linear-gradient(180deg, #9D9D9D 0%, #848484 100%);
        fill: #dddddd;
      }
    }

    //&:active {
    //  opacity: 1;
    //  background-color: rgba(#000, .05);
    //}
  }

  //.input-number__add {
  //  right: 1px;
  //}

}

@media screen and (min-width: 1024px) {
  .product-card__image {
    flex-shrink: 0;

    &:hover .item_overlay {
      background-color: gray;
    }
  }
  .product-image__default-fms {
    min-height: 260px;
  }
}

@media (max-width: 800px) {
  .product-card__prices {
    align-items: baseline;
  }
}

@media screen and (max-width: 767px) {

  .upsell_body {
    .product__info {
      padding: 0 !important;
    }

    .product__gallery {
      padding: 0;
      margin: 0;
    }

    .product__actions {
      padding-left: 0;
    }
  }

  .input-number {
    .input-number__input {
      max-width: 50px;
    }
  }
}

@media (max-width: 576px) {
  .upsell_body {
    .product__content {
      display: block;

      .product__sidebar {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media (max-width: 500px) {
  .upsell_body {
    display: block;
    margin: 0;

    .product__content {

      .product__info {
        .short_description_block {
          display: block !important;
        }
      }
    }

    .product__actions-item--addToCart {
      margin: 4px auto !important;
    }

    .product__gallery {
      margin: 0 !important;
    }
  }
}

@media (max-width: 426px) {
  .upsell_body {
    .product__content {
      .product__gallery {
        .slick-list {
          max-height: 300px;
        }
      }
    }
  }
}